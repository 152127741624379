import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";

import QrScanner from './components/QrScanner';
import QrResults from './components/QrResults';

const App = () => {

  // const [result, setResult] = useState({"urls": ["Task/123", "Task/987"]});
  const [result, setResult] = useState({});
  const [mail, setMail] = useState('');

  const changeResult = (result) => {
    // console.log('changeResult', result);
    const qrResult = JSON.parse(result.decodedText);
    // console.log('changeResult', qrResult);
    setResult(qrResult);
  }

  // const uid = 'sWjc3dbphWvsmdDF'; // Onworks
  // const uid = 'JNFdgmpt44MNmX9q'; // Kaisen Apotheke

  let uid = '';
  const queryParameters = new URLSearchParams(window.location.search);
  uid = queryParameters.get("uid");

  useEffect(() => {
    if (mail === '') {
      axios
      .get('https://scanner.onworks-werbeagentur.de/api/check_apo.php?uid=' + uid)
      .then((response) => {     
        // console.log(response);

        if (response.data.status === 200) { 
          setMail(response.data.email);
          // console.log('setMail', response.data.email)
        } 
      });
    }
  });

  if ( uid === '') {
    return (
      <p>Fehler bei der Einbindung des E-Rezept Scanners. Bitte wenden Sie sich an den Administrator. (Fehlercode: C0023)</p>
    )
  }
  

  return (
    <div className="App">
      { result.urls === undefined ? <QrScanner changeResult={changeResult}/> : undefined }      
      { result.urls !== undefined ? <QrResults result={result} setResult={setResult} mail={mail}/> : undefined }      
    </div>
  );
}

export default App;
