import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Html5Qrcode } from "html5-qrcode";

const QrScanner = (props) => {
  const qrcode = new Html5Qrcode("reader");

  const [scanner, setScanner] = useState(qrcode);
  // const [cameraId, setCameraId] = useState('');
  const [running, setRunning] = useState(false);
  // const [init, setInit] = useState(false);
  // const [initDone, setInitDone] = useState(false);

  

  // const getCameras = () => {
  //   Html5Qrcode.getCameras().then( devices => {
  //     if (devices && devices.length) {
  //       setCameraId(devices[0].id);
  //       // console.log('device found');
  //     }
  //   }).catch( err => {
  //     // console.log('no device detected');
  //   })
  // }

  const startScanner = () => {
    // console.log('start', qrcode);

    setRunning(true);
    scanner
      .start(
        { facingMode: "environment" },
        {
          fps: 20,
          qrbox: { width: 300, height: 400 }
        },
        (decodedText, decodedResults) => {
          // console.log('found qr Code', decodedResults);
          scanner.stop();
          props.changeResult(decodedResults);
        },
        (errorMessage) => {
          // console.log('nothing found');
          
      })
      .catch( (err) => {
        alert('Wir konnten leider keine Kamera an Ihrem Endgerät finden. Rufen Sie die Seite einfach noch einmal mit Ihrem Smartphone oder Tablet auf.')
        setRunning(false);
      });

    // setTimeout( () => {
    //   stopScanner();
    // }, 25000);


    
  }

  const stopScanner = () => {
    setRunning(false);
    // console.log('stop', qrcode);

    scanner.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
    });

  }

  const startButton = () => {
    return (
      <Card onClick={startScanner} style={{padding: '20px', marginTop: '20px', textAlign: 'center'}}>
        <img src="/img/qrcode-solid.png" style={{padding: '20px'}} alt="QR-Code"/>
        <p style={{fontSize: '30px', fontWeight: 'bold', textTransform: 'uppercase'}}>E-Rezept jetzt scannen</p>
      </Card>
    )
  }
  
    return (
      <>
        {/* <button onClick={getCameras}>Scanner aktivieren</button> */}
        
        { !running ? startButton() : undefined}
        { running ? <Button onClick={stopScanner} variant="secondary">Scan Abbrechen</Button> : undefined}

        {/* { cameraId !== '' ? <div><button onClick={startScanner}>start</button></div> : undefined} */}
        {/* { running ? <div><button onClick={stopScanner}>stop</button></div> : undefined} */}
      </>    
    )
  }

export default QrScanner;